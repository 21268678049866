<template>
	<section class="d-flex flex-column-reverse flex-md-row w100 h100">
		<div
			class="container-form pb-16 pt-4"
			:style="{ width: $vuetify.breakpoint.smAndDown ? '100%' : '45%' }"
		>
			<v-card
				max-width="430px"
				class="elevation-0 mx-auto text-center px-4 px-md-2 pt-4 pt-md-16 pb-12 pb-md-0"
			>
				<v-form ref="form" @submit.prevent="submit">
					<div class="mb-4 ff-1-medium font-8 font-md-16">CONTACTO</div>
					<h2 style="font-size: 16px" class="font-weight-thin mb-4 mb-md-12">
						ESTAMOS PARA AYUDARTE, DEJANOS TU CONSULTA
					</h2>
					<v-text-field
						label="Nombre completo"
						v-model="formData['Nombre completo']"
						:rules="defaultRules"
						class="mb-2"
					></v-text-field>
					<v-text-field
						label="Email"
						type="email"
						v-model="formData['Email']"
						:rules="defaultRules.concat((v) => v.includes('@') || 'Email invalido')"
						class="mb-2"
					></v-text-field>
					<v-text-field
						label="Telefono"
						type="number"
						v-model="formData['Telefono']"
						:rules="defaultRules"
						class="mb-2"
					></v-text-field>
					<v-select
						:items="['Servicio Tu Barra', 'Servicio Tu Evento', 'Otro']"
						label="Seleccionar asunto"
						v-model="Asunto"
						:rules="defaultRules"
					>
					</v-select>
					<v-text-field
						label="Consulta"
						v-model="formData['Consulta']"
						:rules="defaultRules"
						class="mb-2"
					></v-text-field>
					<WButton :disabled="loading" :loading="loading" large class="px-10 mt-4 mt-md-6" type="submit">
						Enviar
					</WButton>
				</v-form>
			</v-card>
		</div>
		<div class="container-image h100" :style="{ width: $vuetify.breakpoint.smAndDown ? '100%' : '55%' }">
			<Media width="100%" :height="$vuetify.breakpoint.smAndDown ? '150px' : '100%'" :src="image" cover>
			</Media>
		</div>
		<v-snackbar v-model="snackbar" multi-line top>
			{{ message }}
			<template #action="{ attrs }">
				<v-btn dark text v-bind="attrs" @click="snackbar = false" icon>
					<v-icon large>mdi-close</v-icon>
				</v-btn>
			</template>
		</v-snackbar>
	</section>
</template>

<script>
export default {
	metaInfo: {
		title: 'Contacto',
	},
	pageView: true,
	data() {
		return {
			formData: {
				'Nombre completo': '',
				Email: '',
				Telefono: '',
				Consulta: '',
				Asunto: '',
			},
			defaultRules: [(v) => !!v || 'Campo obligatorio'],
			loading: false,
			message: '',
			snackbar: false,
		}
	},
	computed: {
		image() {
			return this.$srv('Contact_Image.img')
		},
		isValid() {
			return this.$refs.form.validate()
		},
	},
	methods: {
		submit() {
			if (!this.isValid) return
			const htmlContent = Object.entries(this.formData).reduce(
				(acc, [key, val]) => [...acc, `<b>${key}</b><br>${val}`],
				[]
			)
			const data = {
				fullname: this.formData['Nombre completo'],
				htmlContent,
			}
			this.$shopApi.post('/contact-form', {
				data,
				loading: (v) => (this.loading = v),
				done: ({ success }) => {
					if (success) {
						this.$refs.form.reset()
						this.message = 'Mensaje enviado'
					} else {
						this.message = 'Error al enviar el mensaje'
					}
					this.snackbar = true
				},
			})
		},
	},
	mounted() {
		this.$prerenderReady()
	},
}
</script>

<style></style>
